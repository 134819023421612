.footer-container{
    width: 100%;
    height: 450px;
    background: #004a99;
    padding: 60px 0 30px 0;
}
.footer-contet{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
}
.footer-col{
    width: calc(100% / 4);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}
.footer-col h1{
    font-size: 34px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.footer-col p{
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    color: #ecf5ff;
    text-align: justify;
}
.footer-col h3{
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
}
.footer-col span{
    color: #eee;
    font-size: 14px;
}
.footer-col strong{
    font-weight: bolder;
}
.footer-links{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}
.footer-link{
    text-decoration: none;
    color: #ecf5ff;
    transition: all 0.5s;
}
.footer-link:hover{
    color: #007bff;
}
.socials{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.socials a{
    width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: #007bff;
    color: #ecf5ff;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}
.socials a:hover{
    background-color: #156ecc;
}
.subscribe{
    width: 100%;
    height: 40px;
    position: relative;
}
.subscribe input{
    width: 70%;
    height: 100%;
    border: none;
    outline: none;
}
.subscribe button{
    width: 30%;
    height: 100%;
    border: none;
    background-color: #007bff;
    color: #ecf5ff;
    text-transform: capitalize;
    transition: all 0.5s;
}
.subscribe button:hover{
    background-color: #156ecc;
}
.copy{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00428a;
    color: #fff;
}
@media  screen and (max-width:1320px) {
    .footer-col{
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
    }
}
@media  screen and (max-width:1120px) {
    .footer-container{
        width: 100%;
        height: 100%;
        background: #004a99;
        padding: 60px 0 0px 0;
    }
    .footer-col{
        width: 250px;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
    }
    .footer-contet{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 0 20px;
    }
}