.button,
.submit{
    text-align: center;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 13px 42px;
    border-radius: 50px;
    transition: 0.5s;
    text-decoration: none;
    color: #fff;
    background: #007bff;
    border: 3px solid #007bff;
}
.button:hover{
    color: #fff;
    border: 3px solid #fff;
    background-color: transparent;
}
.submit:hover{
    color: #fff;
    border: 3px solid #0655a8;
    background: #0655a8;
}
.button-active{
    text-align: center;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 13px 42px;
    border-radius: 50px;
    transition: 0.5s;
    text-decoration: none;
    color: #fff;
    border: 3px solid #fff;
    background-color: transparent;
}
.button-active:hover{
    color: #fff;
    background: #007bff;
    border: 3px solid #007bff;
}
/* upper */
.upper{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    font-size: 24px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 20px;
    bottom: 20px;
    z-index: 999999;
    display: none;
    transition: all 0.5s;
}
@media  screen and (max-width:820px) {
.button,
.submit,
.button-active{
    text-align: center;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 13px 42px;
    border-radius: 50px;
    transition: 0.5s;
    text-decoration: none;
    color: #fff;
    background: #007bff;
    border: 3px solid #007bff;
}
}
@media  screen and (max-width:600px) {
    .button,
    .submit,
    .button-active{
        text-align: center;
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 13px 42px;
        border-radius: 50px;
        transition: 0.5s;
        text-decoration: none;
        color: #fff;
        background: #007bff;
        border: 3px solid #007bff;
    }
}