.slider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background: #ecf5ff;
    padding: 60px 0;
    box-shadow: inset 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.slider-container h3{
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative;
    font-family: "Montserrat", sans-serif;
}
.slider{
    width: 60%;
    max-width: 60%;
    height: 100%;
}
/* Slide section */
.slide-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.slide-container .profile img{
    width: 120px;
    height: 120px;
    border: 5px solid #fff;
    border-radius: 50%;
}
.slide-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
    padding-left: 20px;
}
.slide-content h4{
    font-size: 26px;
    color: #242424;
    text-align: center;
    font-weight: 700;
    position: relative;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0;
    text-transform: capitalize;
}
.slide-content span{
    font-size: 14px;
    color: #707070;
    text-transform: capitalize;
    margin-bottom: 20px;
}
.slide-content p{
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.slide-pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
}
.slide-pagination--btn{
    width: 15px;
    height: 15px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
}
.slide-pagination--btn:active{
    background-color: #007bff;
}
@media  screen and (max-width:1100px) {
    .slider{
        width: 65%;
        max-width: 65%;
        height: 100%;
    }
}
@media  screen and (max-width:1000px) {
    .slider{
        width: 70%;
        max-width: 70%;
        height: 100%;
    }
}
@media  screen and (max-width:900px) {
    .slider{
        width: 75%;
        max-width: 75%;
        height: 100%;
    }
}
@media  screen and (max-width:800px) {
    .slider{
        width: 80%;
        max-width: 80%;
        height: 100%;
    }
}
@media  screen and (max-width:700px) {
    .slider{
        width: 90%;
        max-width: 90%;
        height: 100%;
    }
}
@media  screen and (max-width:600px) {
    .slider{
        width: 90%;
        max-width: 90%;
        height: 100%;
    }
    .slide-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .slide-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        align-items: center;
        padding-left: 0px;
    }
    .slide-container .profile img{
        width: 120px;
        height: 120px;
        border: 5px solid #fff;
        border-radius: 50%;
        margin-bottom: 10px;
    }
}