.list{
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}
.list:hover .icon-container{
    background-color: #007bff;
    color: #fff;
    transition: all .3s;
}
.icon-container{
    width: 64px;
    height: 64px;
    min-width: 64px;
    color: #007bff;
    border-radius: 50%;
    border:2px solid #007bff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    transition: all .3s;
}
.list-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
}
.list-content a{
    width: 200px;
    font-family: "Montserrat", sans-serif;
    color: #283d50;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
}
.list-content p{
    text-align: left;
    line-height: 24px;
    font-size: 14px;
}
@media  screen and (max-width:1200px) {
    .list{
        height: 100px;
    }
    .icon-container{
        width: 54px;
        height: 54px;
        min-width: 54px;
        font-size: 22px;
    }
}
@media  screen and (max-width:768px) {
    .list{
        height: 100%;
    }
    .icon-container{
        width: 44px;
        height: 44px;
        min-width: 44px;
        font-size: 20px;
    }
    .list-content a{
        width: 200px;
        font-family: "Montserrat", sans-serif;
        color: #283d50;
        text-decoration: none;
        font-weight: 600;
        font-size: 17px;
    }
}