.tab-container input{
    cursor: pointer;
    margin: 15px 15px 15px 0;
    display: inline-block;
    padding: 6px 20px;
    font-size: 14px;
    line-height: 20px;
    color: #007bff;
    border-radius: 50px;
    text-transform: uppercase;
    background: #e8f3ff;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border: none;
}
.tab-container input:hover{
    background: #007bff;
    color: #fff;
}
@media  screen and (max-width:600px) {
    .tab-container input{
        cursor: pointer;
        margin: 15px 15px 15px 0;
        display: inline-block;
        padding: 6px 20px;
        font-size: 12px;
        line-height: 20px;
        color: #007bff;
        border-radius: 50px;
        text-transform: uppercase;
        background: #e8f3ff;
        margin-bottom: 5px;
        transition: all 0.3s ease-in-out;
        border: none;
    }
}