.about-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 50px;
}
.about-container h3{
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.par{
    text-align: center;
    font-size: 15px;
    padding-bottom: 60px;
    color: #556877;
    width: 40%;
    font-family: "Open Sans", sans-serif;
}
.about-row{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding:0 2%;
    gap: 90px;
    margin-bottom: 100px;
}
.about-col{
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}
.about-col p{
    width: 100%;
    line-height: 26px;
    font-size: 18px;
    color: #444;
    text-align: left;
}
.about-col img{
    width: 500px;
    height: 500px;
}
.about-col h4{
    font-weight: 600;
    font-size: 24px;
    width: 80%;
    font-family: "Montserrat", sans-serif;
}
@media  screen and (max-width:1200px) {
    .about-col p{
        width: 100%;
        line-height: 26px;
        font-size: 15px;
        color: #444;
        text-align: left;
    }
    .about-col img{
        width: 100%;
        height: 100%;
    }
}
@media  screen and (max-width:1000px) {
    .about-row{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding:0 2%;
        gap: 90px;
        margin-bottom: 100px;
    }
    .about-col{
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
    }
    .about-col h4{
        font-weight: 600;
        font-size: 24px;
        width: 100% !important;
        font-family: "Montserrat", sans-serif;
        text-align: center;
    }
    .par{
        text-align: center;
        font-size: 15px;
        padding-bottom: 60px;
        color: #556877;
        width: 55% !important;
        font-family: "Open Sans", sans-serif;
    }
}
@media  screen and (max-width:768px) {
    .about-row{
        margin-bottom: 50px;
    }
    .about-container h3{
        font-size: 32px;
    }
    .par{
        text-align: center;
        font-size: 15px;
        padding-bottom: 60px;
        color: #556877;
        width: 75% !important;
        font-family: "Open Sans", sans-serif;
    }
    .about-col p{
        width: 100%;
        line-height: 26px;
        font-size: 15px;
        color: #444;
        text-align: justify;
    }
    .about-col h4{
        font-weight: 500;
        font-size: 20px;
        width: 100% !important;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        line-height: 1.5;
    }
}