.contact-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 50px;
}
.contact-container h3{
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.contact{
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 1%;
}
.maps-container{
    width: 100%;
}
.contact-content{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
}
.contact-header{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.contact-col{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-col p{
    font-size: 14px;
    margin-top: 15px;
}
.contact-icon{
    font-size: 32px;
    color: #007bff;
    margin-right: 10px;
}
.contact-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.contact-group{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.contact-form input{
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 10px;
    border: none;
    outline: 1px solid #ced4da;
    border-radius: .25rem;
}
.contact-form input::placeholder,
.contact-form textarea::placeholder{
    font-size: 14px;
    text-transform: capitalize;
}
.contact-form textarea{
    width: 100%;
    height: 100px;
    font-size: 14px;
    padding: 5px;
    outline: 1px solid #ced4da;
    border: none;
    border-radius: .25rem;
}
.contact-form input:focus,
.contact-form textarea:focus{
    outline: 1px solid #007bff;
    outline: 1px solid #007bff;
    border: none;
}
@media  screen and (max-width:1150px) {
    .contact-header{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        gap: 10px;
    }
    .contact-col{
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
    .contact-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
@media  screen and (max-width:800px) {
    .contact{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0 1%;
        padding: 10px;
        padding-bottom: 50px;
    }
}