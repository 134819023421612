.team-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 50px 0;
    font-family: "Montserrat", sans-serif;
}
.team-container h3{
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.par{
    text-align: center;
    font-size: 15px;
    padding-bottom: 60px;
    color: #556877;
    width: 40%;
    font-family: "Open Sans", sans-serif;
}
.members{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.member{
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}
.member img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.member-content{
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(9, 79, 153, 0.6);
    transition: top 0.5s ease-in-out;
}
.member:hover .member-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    top: 0;
}
.member-content h3{
    font-size: 30px;
    font-weight: 600;
    color: #fff;
}
.member-content span{
    font-size: 14px;
    font-weight: 400;
}
.member-social{
    padding-top: 20px;
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.social-icon{
    color: #fff;
    width: 24px;
    height: 24px;
    text-decoration: none;
}
.social-icon:hover{
    color: #0084ff;
}
@media  screen and (max-width:1500px) {
    .member{
        width: 280px;
        min-width: 280px;
        height: 280px;
        min-height: 280px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }
}
@media  screen and (max-width:1300px) {
    .member{
        width: 250px;
        min-width: 250px;
        height: 250px;
        min-height: 250px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }
}
@media  screen and (max-width:1100px) {
    .member{
        width: 220px;
        min-width: 220px;
        height: 220px;
        min-height: 220px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }
    .member-content h3{
        font-size: 28px;
        font-weight: 600;
    }
    .social-icon{
        color: #fff;
        width: 22px;
        height: 22px;
    }
}
@media  screen and (max-width:1000px) {
    .member{
        width: 200px;
        min-width: 200px;
        height: 200px;
        min-height: 200px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }
}
@media  screen and (max-width:900px) {
    .member{
        width: 180px;
        min-width: 180px;
        height: 180px;
        min-height: 180px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
    }
}
@media  screen and (max-width:800px) {
    .members{
        width: 85%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }
    .member-content h3{
        font-size: 25px;
        font-weight: 500;
        color: #fff;
    }
    .member-content span{
        font-size: 12px;
        font-weight: 400;
    }
    .social-icon{
        color: #fff;
        width: 18px;
        height: 18px;
        text-decoration: none;
    }
}