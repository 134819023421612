header{
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.3);
    color: #444;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    transition: all 0.5s;
}
.header-links{
    width: 700px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.header-link{
    text-decoration: none;
    color: #004289;
    transition: 0.3s;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
}
.header-link:hover{
    color:#007bff;
}
.logo{
    width: 70px;
    height: 70px;
}
.logo img{
    width: 100%;
    height: 100%;
}
.menu{
    font-size: 40px;
    color: #004289;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media  screen and (max-width:970px) {
    .header-links{
        width: 700px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
    }
}
@media  screen and (max-width:786px) {
    .menu{
        font-size: 40px;
        color: #004289;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .header-links{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        background-color: #007bff;
        color: #444;
        position: absolute;
        left: 0;
        top: 80px;
        min-height: 100vh;
        padding-top: 20px;
        transition: all 0.5s;
    }
    .header-link{
        text-decoration: none;
        color: #121212;
        transition: 0.3s;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        text-transform: capitalize;
    }
}