.choseCard{
    background: #00458f;
    border-color: #00458f;
    border-radius: 10px;
    padding: 15px 20px;
    text-align: center;
    color: #fff;
    transition: 0.3s ease-in-out;
    width: 350px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}
.choseCard-icon{
    font-size: 48px;
    color: #bfddfe;
}
.choseCard-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.choseCard-body h5{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: .75rem;
    font-family: "Montserrat", sans-serif;
    color: #fff;
}
.choseCard-body p{
    font-size: 15px;
    color: #d8eafe;
    font-family: "Open Sans", sans-serif;
}
.choseCard-body a{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    transition: 0.3s ease-in-out;
    border-bottom: #00458f solid 3px;
    text-decoration: none;
    background-color: transparent;
}
.choseCard:hover{
    background: #003b7a;
    border-color: #003b7a;
}
.choseCard:hover .choseCard-body a{
    border-bottom: #00458f solid 3px;
}
.choseCard-body a:hover{
    border-bottom: #fff solid 3px !important;
}
@media  screen and (max-width:950px) {
    .choseCard{
        height: auto;
    }  
}