@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body{
  font-family: "Montserrat", sans-serif;
  background: #fff;
}
*{
  margin: 0;
  padding: 0;
}

