.counter{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-top: 50px;
}
.counter span{
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 48px;
    display: block;
    color: #fff;
}
.counter p{
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: #cce5ff;
    text-transform: capitalize;
}
@media  screen and (max-width:950px) {
    .counter span{
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 42px;
        display: block;
        color: #fff;
    }
    .counter p{
        padding: 0;
        margin: 0 0 20px 0;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        color: #cce5ff;
        text-transform: capitalize;
    }
}
@media  screen and (max-width:800px) {
    .counter span{
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 35px;
        display: block;
        color: #fff;
    }
    .counter p{
        padding: 0;
        margin: 0 0 20px 0;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: #cce5ff;
        text-transform: capitalize;
    }
}
@media  screen and (max-width:700px) {
    .counter span{
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 30px;
        display: block;
        color: #fff;
    }
    .counter p{
        padding: 0;
        margin: 0 0 20px 0;
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: #cce5ff;
        text-transform: capitalize;
    }
    .counter{
        width: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        margin-top: 50px;
    }
}
@media  screen and (max-width:600px) {
    .counter{
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        margin-top: 10px;
    }
}