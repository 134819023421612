#intro{
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: url("../../../../public/intro-bg.png") center bottom no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.intro-info{
    width: 565px;
    height: 100%;
}
.intro-info h2{
    color: #fff;
    margin-bottom: 40px;
    font-size: 58px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    line-height: 1.3;
}
.intro-info span{
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
}
.intro-info div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}
.intro-bg{
    width: 665px;
    height: 365px;
}
.intro-bg img{
    width: 100%;
    height: 100%;
}
@media  screen and (max-width:1280px) {
    .intro-info{
        padding-left: 10px;
    }
}
@media  screen and (max-width:1080px) {
    .intro-info{
        padding-left: 10px;
    }
    .intro-info h2{
        color: #fff;
        margin-bottom: 30px;
        font-size: 40px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        line-height: 1.2;
    }
    .intro-bg{
        width: 565px;
        height: 315px;
    }
}
@media  screen and (max-width:1000px) {
    .intro-info{
        padding-left: 10px;
    }
    .intro-info h2{
        color: #fff;
        margin-bottom: 30px;
        font-size: 38px;
        font-weight: 600;
        font-family: "Montserrat", sans-serif;
        line-height: 1.2;
    }
    .intro-bg{
        width: 465px;
        height: 265px;
    }
}
@media  screen and (max-width:820px) {
    #intro{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-top: 80px;
    }
    .intro-info h2{
        text-align: center;
    }
    .intro-info div{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .intro-info{
        width: 100%;
        height: 100%;
        padding-left: 0;
    }
   
}
@media  screen and (max-width:500px) {
    .intro-bg{
        width: 100%;
        height: 265px;
    }
}