.chose-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 50px;
    background-color: #004a99;
}
.chose-container h3{
    font-size: 36px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.chose-container .par{
    text-align: center;
    font-size: 15px;
    padding-bottom: 60px;
    color: #fff;
    width: 40%;
    font-family: "Open Sans", sans-serif;
}
.chose-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding-bottom: 30px;
}
.counter-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 30px;
}
@media  screen and (max-width:1200px) {
    .chose-row{
        padding: 0 2%;
    }
}
@media  screen and (max-width:800px) {
    .chose-row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 30px;
    }
    .counter-row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-bottom: 30px;
    }
}
@media  screen and (max-width:600px) {
    .counter-row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        padding-bottom: 30px;
    }
}