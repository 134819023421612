.cards{
    width: 440px;
    height: 192px;
    padding: 30px 30px 30px 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    color: #444;
    font-family: "Open Sans", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
}
.cards:hover{
    transform: translateY(-10px);
}
.cards:hover a{
    color: #007bff;
}
.card-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.cardIcon-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cards .icon{
    font-size: 64px;
    line-height: 1;
    transition: 0.5s;
    margin-left: -20px;
}
.cards a {
    color: #111;
    transition: 0.5s;
    text-decoration: none;
    background-color: transparent;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;    
    /* margin-bottom: 15px; */
    font-weight: 700;
    width: 100%;
}
.cards a:hover{
    color: #007bff;
}
.cards p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
}
@media  screen and (max-width:1022px) {
    .cards{
        width: 45%;
    }
}
@media  screen and (max-width:768px) {
    .cards{
        width: 90%;
    }
    .cards a {
        font-size: 18px;
        font-weight: 600;
    }
}