.services-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 50px 0 70px 0;
    background-color: #ecf5ff;
    box-shadow: inset 0px 0px 12px 0px rgba(0,0,0,0.1);
}
.services-container h3{
    font-size: 36px;
    color: #283d50;
    text-align: center;
    font-weight: 500;
    position: relative;
    text-transform: capitalize;
    font-family: "Montserrat", sans-serif;
}
.par{
    text-align: center;
    font-size: 15px;
    padding-bottom: 60px;
    color: #556877;
    width: 40%;
    font-family: "Open Sans", sans-serif;
}
.service-content{
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}
@media  screen and (max-width:768px) {
    .services-container h3{
        font-size: 32px;
    }
}